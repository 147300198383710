<template>
	<nav class="header-navbar navbar-expand-lg navbar navbar-with-menu floating-nav navbar-light navbar-shadow">
        <div class="navbar-wrapper">
            <div class="navbar-container content">
                <div class="navbar-collapse" id="navbar-mobile">
                    <div class="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                        <!-- Mobile -->
                        <ul class="nav navbar-nav">
                            <li class="nav-item mobile-menu d-lg-none mr-auto">
                                <a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#" data-toggle="collapse">
                                    <i class="toggle-icon collapse-toggle-icon ficon feather icon-menu" data-ticon="icon-disc"></i>
                                </a>
                            </li>
                        </ul>

                        <!-- Desktop -->
                        <ul class="nav navbar-nav bookmark-icons">
                            <li class="nav-item d-none d-lg-block">
                                <a class="nav-link modern-nav-toggle pr-0" data-toggle="collapse">
                                    <i class="toggle-icon collapse-toggle-icon ficon feather icon-menu" data-ticon="icon-disc"></i>
                                </a>
                            </li>

                            <li class="nav-item d-none d-lg-block" @click="fullscreen()">
                                <a class="nav-link">
                                    <i v-if="isFullScreen == 0" class="ficon feather icon-maximize"></i>
                                    <i v-else class="ficon feather icon-minimize"></i>
                                </a>
                            </li>
                        </ul>
                    </div>   
                    <ul class="nav navbar-nav float-right">
                        <li class="dropdown dropdown-user nav-item">
                            <a class="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                                <div class="user-nav d-sm-flex ml-1 text-right">
                                    <span class="text-primary font-600 f-16 d-none d-lg-block">{{ logged_user.name }}</span>
                                    <span class="text-primary font-600 f-16 d-lg-none" style="margin-top:8px;">{{ logged_user.name }}</span>
                                    <span class="text-right text-black f-12">{{ logged_user.role == 'spv' ? 'Supervisor' : logged_user.role == 'admin' ? 'Admin' : 'Agen' }}</span>
                                </div>
                                <span v-can="['spv', 'admin']">
                                    <img class="round" :src="temp_photo ? temp_photo:'/assets/img/icon/profile.png'" alt="avatar" height="40" width="40">
                                </span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                                <section class="d-lg-none">
                                    <div class="col" style="margin-top: 10x;">
                                        <p class="text-primary font-600 f-16 mb-0">{{ logged_user.name }}</p>
                                        <span class="text-right text-black f-12">{{ logged_user.role == 'spv' ? 'Supervisor' : logged_user.role == 'admin' ? 'Admin' : 'Agen' }}</span>
                                    </div>
                                    <div class="dropdown-divider"></div>
                                </section>
                                <router-link to="/user/edit_profile" tag="a" class="dropdown-item" v-can="['spv', 'admin']">
                                    <i class="feather icon-user"></i> Edit Profile
                                </router-link>
                                <router-link to="/user/agen/edit_profile" tag="a" class="dropdown-item" v-can="['agen']">
                                    <i class="feather icon-user"></i> Edit Profile
                                </router-link>
                                <router-link to="/user/change_password" tag="a" class="dropdown-item">
                                    <i class="feather icon-lock"></i> Change Password
                                </router-link>
                                <div class="dropdown-divider"></div>
                                <a href="javascript:;" @click="logout()" class="dropdown-item">
                                	<i class="feather icon-power"></i> Logout
                                </a>
                            </div>
                        </li>
                    </ul>                 
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import $ from 'jquery'

export default {
    data() {
        return {
            isFullScreen: 0,
            temp_photo: null,
            logged_user: {
                id: localStorage.getItem('id'),
                name: localStorage.getItem('name'),
                role: localStorage.getItem('role')
            }
        }
    },
	methods: {
		fullscreen(){
            let elem = document.documentElement
            if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
                if (elem.requestFullScreen) {
                    elem.requestFullScreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullScreen) {
                    elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
                this.isFullScreen = 1
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
                this.isFullScreen = 0
            }
        },
        logout() {
            let _ = this
            $(function(){                
                $('body').removeClass('menu-collapsed')
                $('body').addClass('menu-expanded')
            })
			_.$store.dispatch('logout')
			window.location = '/login'
        }
	},
    mounted() {
        let _ = this
        if (localStorage.getItem('file_avatar') != null){
            _.temp_photo = localStorage.getItem('file_avatar')
        }

        _.$root.$on('changeProfile', (data) => {
            if (data.file_avatar != null){
                localStorage.setItem('file_avatar', data.file_avatar)
                _.temp_photo = data.file_avatar
            }
            
            localStorage.setItem('name', data.name)
            _.logged_user.name = data.name
        })
    },
}
</script>