<template>
	<div>
		<Navbar />
	    <Menu />
	    <div class="app-content content">
	        <div class="content-wrapper">
				<div class="content-body">
					<div class="mb-2" v-if="$route.meta.icon || $route.meta.pageTitle">
						<router-link to="/">
							<i class="material-icons">{{ $route.meta.icon }}</i>
							<span class="menu-title align-self-center ml-1" style="vertical-align: super;">{{ $route.meta.pageTitle }}</span>
						</router-link>
					</div>
					<router-view/>
				</div>
	        </div>
	    </div>
		<div class="sidenav-overlay"></div>
		<Footer />
	</div>
</template>

<script>
import Navbar from '@/layouts/components/Navbar'
import Menu from '@/layouts/components/Menu'
import Footer from '@/layouts/components/Footer'
export default {
	components: {
		Navbar,
		Menu,
		Footer
	}
}
</script>