<template>
	<div class="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true" style="background-color: #FFF; border-top-right-radius: 10px">
        <router-link to="/">
            <div class="navbar-header pointer text-center">
                <img :src="setting.logo ? setting.logo : '/assets/img/logo.png'" class="img-fluid rounded" width="100px" alt="">
            </div>
        </router-link>

        <div class="main-menu-content">
            <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation" style="margin-bottom: 50px;">
                <li class="nav-item pl-0" v-for="(data, idx) in menus" :key="idx" 
                    :class="isActive(data.active) + (data.has_sub ? ' has-sub ' : '') + (data.sub && data.sub.length > 0 && isActive(data.active) ? ' open' : '')">
                    <router-link :to="data.url" v-if="isAllowed(data.roles)">
                        <i class="material-icons f-25 ml-1">{{data.icon}}</i>
                        <span class="menu-title align-self-center font-400">{{data.name}}</span>
                        <span class="badge badge badge-danger badge-pill float-right" v-if="data.counter > 0">{{data.counter}}</span>
                    </router-link>
                    <ul class="menu-content">
                        <li v-for="(dt, i) in data.sub" :key="i">
                            <router-link :to="dt.url">
                                <i class="ml-2 fa fa-circle"></i>
                                <span class="menu-item">{{dt.name}}</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            menus: [
                {
                    name: 'Dashboard',
                    url: '/',
                    icon: 'grid_view',
                    active: ['Home'],
                    roles: ['spv', 'admin']
                },
                {
                    name: 'Admin',
                    url: '/admin',
                    icon: 'support_agent',
                    active: [
                        'AdminIndex',
                        'AdminFormAdd',
                        'AdminFormEdit',
                    ],
                    roles: ['spv']
                },
                {
                    name: 'Agen',
                    url: '/agen',
                    icon: 'people',
                    active: [
                        'AgenIndex',
                        'AgenFormAdd',
                        'AgenFormEdit',
                        'AgenDetail',
                    ],
                    roles: ['spv', 'admin']
                },
                {
                    name: 'Customer',
                    url: '/customer',
                    icon: 'groups',
                    active: [
                        'CustomerIndex',
                        'CustomerFormAdd',
                        'CustomerFormEdit',
                    ],
                    roles: ['spv', 'admin']
                },
                {
                    name: 'Produk',
                    url: '/produk',
                    icon: 'category',
                    active: [
                        'ProdukIndex',
                        'ProdukFormAdd',
                        'ProdukFormEdit',
                        'ProdukOnlineIndex',
                        'ProdukOnlineFormAdd',
                        'ProdukOnlineFormEdit',
                        'ProdukMerchandiseIndex',
                        'ProdukMerchandiseFormAdd',
                        'ProdukMerchandiseFormEdit',
                    ],
                    roles: ['spv'],
                    has_sub: true,
                    sub: [
                        {
                            name: 'Produk Offline',
                            url: '/produk',
                        },
                        {
                            name: 'Produk Online',
                            url: '/produk-online',
                        },
                        {
                            name: 'Produk Merchandise',
                            url: '/produk-merchandise',
                        }
                    ]
                },
                {
                    name: 'Penjualan',
                    url: '/penjualan',
                    icon: 'shopping_cart',
                    active: [
                        'PenjualanIndex',
                        'PenjualanFormAdd',
                        'PenjualanFormEdit',
                        'PenjualanOnlineIndex',
                        'PenjualanOnlineDetail',
                    ],
                    roles: ['spv', 'admin'],
                    has_sub: true,
                    sub: [
                        {
                            name: 'Penjualan Offline',
                            url: '/penjualan',
                        },
                        {
                            name: 'Penjualan Online',
                            url: '/penjualan-online',
                        },
                        {
                            name: 'Penjualan Merchandise',
                            url: '/penjualan-merchandise',
                        }
                    ]
                },
                // {
                //     name: 'Kontak',
                //     url: '/kontak',
                //     icon: 'contact_phone',
                //     active: [
                //         'KontakIndex',
                //         'KontakFormAdd',
                //         'KontakFormEdit'
                //     ],
                //     roles: ['spv']
                // },
                {
                    name: 'Event',
                    url: '/event',
                    icon: 'ad_units',
                    active: [
                        'EventIndex',
                        'EventFormAdd',
                        'EventFormEdit'
                    ],
                    roles: ['spv']
                },
                {
                    name: 'Pengaturan',
                    url: '/setting',
                    icon: 'settings',
                    active: ['PengaturanForm'],
                    roles: ['spv']
                },
                {
                    name: 'Dashboard',
                    url: '/dashboard_agent',
                    icon: 'grid_view',
                    active: ['DashboardAgent'],
                    roles: ['agent']
                },
                {
                    name: 'Agen',
                    url: '/bawahan',
                    icon: 'people',
                    active: ['BawahanAgent'],
                    roles: ['agent']
                },
            ],
            setting: []
        }
    },
    methods: {
        isActive(data){
            let _ = this
            let isThere = data.filter(x => x == _.$route.name)
            if (isThere.length > 0) {
                return 'active';
            }
            return '';
        },
        isAllowed(data){
            let _ = this
            let isThere = data.filter(x => x == _.$store.state.user.role)
            if (isThere.length > 0) {
                return true;
            } else {
                return false;
            }
        },
        getSetting()
        {
            let _ = this
            _.axios.get('/setting')
                .then( resp => {
                    _.setting = resp.data.data
                })
        }
    },
    mounted() {
        let _ = this

        _.getSetting()

        _.$root.$on('changeMenuCounter', (data) => {
            _.menus[data.id_menu].counter -= data.counter
            _.$forceUpdate();
        })

        _.$root.$on('changeMenuCounterToZero', (data) => {
            _.menus[data.id_menu].counter = 0
            _.$forceUpdate();
        })

        _.$root.$on('changeMenuCounterTo', (data) => {
            _.menus[data.id_menu].counter = data.counter
            _.$forceUpdate();
        })
    },
}
</script>